import React, { useState, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./accessoiresItems.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Casques = () => {
  const [activeSizeOption1, setActiveSizeOption1] = useState("M")
  const [activeSizeOption2, setActiveSizeOption2] = useState("M")
  const [activeSizeOption3, setActiveSizeOption3] = useState("M")
  const [activeSizeOption4, setActiveSizeOption4] = useState("M")
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      casque1: allStrapiCasque(
        filter: { titre: { eq: "Casque Abus Vert Pomme" } }
      ) {
        nodes {
          images {
            url
          }
        }
      }
      casque2: allStrapiCasque(
        filter: { titre: { eq: "Casque Abus Jaune Visière" } }
      ) {
        nodes {
          images {
            url
          }
        }
      }
      casque3: allStrapiCasque(filter: { titre: { eq: "Kask Bleu Visière" } }) {
        nodes {
          images {
            url
          }
        }
      }
      casque4: file(
        relativePath: { eq: "images/accessoires/casques/casque4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="casques pour vélos électriques"
        description="Sélection des casques pour vélos électriques"
      />
      <main className={styles.main}>
        <h1>Casques</h1>
        <Link to="/accessoires" className={styles.back}>
          <span>&lt;&lt; accessoires</span>
        </Link>
        <section className={styles.container}>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Casque Abus vert pomme</h6>
              <p className={styles.price}>59,00 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.casque1} />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption1("XS")}
                className={
                  activeSizeOption1 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption1("S")}
                className={
                  activeSizeOption1 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption1("M")}
                className={
                  activeSizeOption1 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption1("L")}
                className={
                  activeSizeOption1 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption1("XL")}
                className={
                  activeSizeOption1 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Casque vélo Abus Scraper 3.0 pensé premièrement pour la sécurité
              des enfants.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Casque Abus vert pomme",
                  `${activeSizeOption1}`,
                  1,
                  59.0,
                  "accessoires/casques/casque1.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <div className={styles.card}>
            <div className={styles.heading}>
              <h6>Casque Visière Abus jaune</h6>
              <p className={styles.price}>79,00 €</p>
            </div>
            <div className={styles.frame}>
              <SwiperSlider data={data.casque2} />
            </div>
            <div className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption2("XS")}
                className={
                  activeSizeOption2 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption2("S")}
                className={
                  activeSizeOption2 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption2("M")}
                className={
                  activeSizeOption2 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption2("L")}
                className={
                  activeSizeOption2 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption2("XL")}
                className={
                  activeSizeOption2 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </div>
            <p className={styles.description}>
              Ce casque de vélo de ville vous permet de circuler la nuit en
              étant visible des usagers de la route (piétons, automobilistes,
              ...). Outre sa couleur jaune fluo, il est équipé d'une grande
              lumière LED à l'arrière, rechargeable via une batterie par clé USB
              : ce casque vous offre ainsi une visibilité à 180°.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Casque Visière Abus jaune",
                  `${activeSizeOption2}`,
                  1,
                  79,
                  "accessoires/casques/casque2.jpg"
                )
              }
              value="Acheter"
            />
          </div>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Casque Kask Visière bleu</h6>
              <p className={styles.price}>120,00 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.casque3} />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption3("XS")}
                className={
                  activeSizeOption3 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption3("S")}
                className={
                  activeSizeOption3 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption3("M")}
                className={
                  activeSizeOption3 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption3("L")}
                className={
                  activeSizeOption3 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption3("XL")}
                className={
                  activeSizeOption3 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Robuste et solide pour protéger la tête en cas de chute, le casque
              Lifestyle de la marque Kask a été conçu et fait en Italie dans le
              respect de la norme EN1078 en la matière. Spécialement adapté pour
              le cycliste urbain, mais aussi utilisable à trottinette et en
              skateboard, il garantit une haute protection avec sa coque bien
              enveloppante en polycarbonate à film de soie. À l'intérieur, elle
              comprend un revêtement en polystyrène.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Casque Visière bleu ciel",
                  `${activeSizeOption3}`,
                  1,
                  120.0,
                  "accessoires/casques/casque3.png"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Casque Abus Noir</h6>
              <p className={styles.price}>80,00 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.casque4.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption4("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption4("XS")}
                className={
                  activeSizeOption4 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption4("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption4("S")}
                className={
                  activeSizeOption4 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption4("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption4("M")}
                className={
                  activeSizeOption4 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption4("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption4("L")}
                className={
                  activeSizeOption4 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption4("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption4("XL")}
                className={
                  activeSizeOption4 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Le Pedelec 2.0 est l’association sans compromis du confort et de
              la protection. Complété par des fonctionnalités intelligentes, il
              est le compagnon idéal de vos trajets en ville.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Casque Abus Noir",
                  `${activeSizeOption4}`,
                  1,
                  80.0,
                  "accessoires/casques/casque4.jpg"
                )
              }
              value="Acheter"
            />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Casques
